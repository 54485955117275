<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <TextInput
                  v-model="form.title"
                  type="text"
                  :label="$t('Title')"
                  :tooltipMessage="$t('Title')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">
                {{ $t("Company") }}
              </label>
              <MultiSelectInput
                v-model="form.company"
                :options="customers"
                label="companyName"
                trackBy="id"
                :key="form.company"
                moduleName="customers"
                :multiple="false"
                ><template #option="{ props }">
                  <div>
                    <p>
                      {{ props.option.companyNumber }} - {{
                        props.option.companyName
                      }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">
                {{ $t("Permissions") }}</label
              >
              <treeselect
                v-model="form.permissions"
                :valueConsistsOf="'LEAF_PRIORITY'"
                :options="permissionsTree"
                :multiple="true"
              >
                <template v-slot:value-label="{ node }">{{
                  generateCustomLabel(node)
                }}</template>
              </treeselect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">{{
                $t("Can Register")
              }}</label>
              <input
                id="can_register"
                class="form-check-input-custom ml-2"
                type="checkbox"
                v-model="form.can_register"
                name="can_register"
              />
            </div>
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">{{
                $t("Active")
              }}</label>

              <input
                id="active"
                class="form-check-input-custom ml-2"
                v-model="form.active"
                type="checkbox"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="updateRole"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update Role") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { v4 as uuidv4 } from "uuid";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    Treeselect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("permissions", ["permissions"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Roles"),
          to: "/roles",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        title: "",
        company: {},
        permissions: [],
        can_register: 0,
        active: 0,
      },
      permissionsTree: [],
      groups: {},
    };
  },

  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("customers/list");
      this.$store.dispatch("permissions/list", {
        limit_start: 0,
        limit_count: 1000,
      });
      this.$store
        .dispatch("roles/show", {
          id: this.$route.params.id,
        })
        .then(async (res) => {
          var response = res?.data;
          this.form.title = response?.title;
          this.form.permissions = response?.permissions;
          this.form.can_register = response?.can_register ? true : false;
          this.form.active = response?.active ? true : false;

          const permissionsTree = this.createBasePermissionGroups();
          this.createGroupsBasedOnGrouping(permissionsTree);
          this.createSectionedGroups();
          if (response.company_id) {
            const company = await this.$store.dispatch(
              "customers/show",
              response.company_id
            );
            this.form.company = company.data.modelData;
            let customersData = [...this.customers];
            const exists = customersData.some(
              (c) => c.id === company.data.modelData.id
            );
            if (!exists) {
              customersData.push({ ...company.data.modelData });
              this.$store.commit("customers/customers", customersData);
            }
          }
        });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },

  methods: {
    async updateRole() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const response = await this.$store.dispatch("roles/update", {
            id: this.$route.params.id,
            title: this.form.title,
            permissions: this.form.permissions,
            company_id: this.form.company?.id ?? "",
            set_company_id: this.form.company?.id ?? "",
            can_register: this.form.can_register === true ? 1 : 0,
            active: this.form.active === true ? 1 : 0,
          });
          if (
            response.hasOwnProperty("message_type") &&
            response.message_type == "error"
          ) {
          } else {
            this.$router.push("/roles");
          }
        }
      });
    },
    createBasePermissionGroups() {
      const distinctPermissions = new Set();
      const permissionsTree = {};
      this.permissions.forEach((permission) => {
        if (typeof permission?.title === "string") {
          const permissionKey = permission.title.split(".")?.[0];
          const permissionName = permission.title.split(".")?.[1];
          if (distinctPermissions.has(permissionKey)) {
            const permissionNode = permissionsTree[permissionKey];
            if (permissionNode["children"] !== undefined) {
              permissionNode["children"].push({
                id: permission.id,
                label: permissionName,
                grouping: permission.grouping,
                description: permission.description,
              });
            }
          } else {
            distinctPermissions.add(permissionKey);
            permissionsTree[permissionKey] = {
              id: uuidv4(),
              label: permission.title.split(".")?.[0],
              grouping: permission.grouping,
              description: permission.description,
            };
            if (permission.title.split(".")?.[0] !== permission.title) {
              permissionsTree[permissionKey]["children"] = [
                {
                  id: permission.id,
                  label: permissionName,
                  grouping: permission.grouping,
                  description: permission.description,
                },
              ];
            } else {
              permissionsTree[permissionKey]["id"] = permission.id;
            }
          }
        }
      });
      return permissionsTree;
    },
    createGroupsBasedOnGrouping(permissionsTree) {
      Object.values(permissionsTree).forEach((permission) => {
        if (!permission.grouping) {
          this.groups[permission.label] = permission;
        } else {
          if (!this.groups[permission.grouping])
            this.groups[permission.grouping] = [permission];
          else this.groups[permission.grouping].push(permission);
        }
      });
    },
    createSectionedGroups() {
      let sectionedGroups = [];
      for (let group in this.groups) {
        let keys = group.split("/");
        let parent = null;
        keys.forEach((key, index) => {
          if (index === 0) {
            let foundGroup = sectionedGroups.find(
              (improvedGroup) => improvedGroup.label === key
            );
            if (!foundGroup) {
              if (this.groups[group] instanceof Array) {
                sectionedGroups.push({
                  id: uuidv4(),
                  label: key,
                  children: [],
                });
              } else {
                sectionedGroups.push(this.groups[group]);
              }
            }
            parent = sectionedGroups.find(
              (improvedGroup) => improvedGroup.label === key
            );
          } else {
            let foundGroup = parent.children.find(
              (child) => child.label === key
            );
            if (!foundGroup) {
              parent.children.push({
                id: uuidv4(),
                label: key,
                children: [],
              });
            }
            parent = parent.children.find((child) => child.label === key);
          }
        });
        if (this.groups[group] instanceof Array) {
          parent.children = [...parent.children, ...this.groups[group]];
        }
      }
      this.permissionsTree = [...sectionedGroups];
    },
    generateCustomLabel(node) {
      const parentLabel = this.findParentLabel(node);
      return parentLabel ? `${parentLabel}.${node.label}` : node.label;
    },
    findParentLabel(node, parentLabel = "") {
      if (node.parentNode) {
        parentLabel = this.findParentLabel(node.parentNode, parentLabel);
        return parentLabel
          ? `${parentLabel}.${node.parentNode.label}`
          : node.parentNode.label;
      }
      return parentLabel;
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
